import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet'

/* COMPONENTS */
import Menu from '../components/receitas/receitas-header'
import Layout from '../components/Layout/index'
import SideBar from '../components/receitas/SideBarReceitas/sideBarReceitas'
import SectionLancamentos from '../components/SectionLancamentos/sectionLancamentos'
import InputSearch from '../components/receitas/SideBarReceitas/inputSearch'
import slugify from 'slugify'

/*  STYLES */
import '../pages/styles/receitas.scss'

const ReceitasTags = ({ data, pageContext }) => {
  // eslint-disable-next-line no-unused-vars
  const [receitas, setReceitas] = useState(data.receitas.edges)
  const { categoriaSlug } = data.receitas.edges[0] !== undefined ? data.receitas.edges[0].node : ''
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/receitas/', name: 'Receitas' },
      { path: '/receitas/tags/', name: 'Tags' },
      { path: `/receitas/tags/${slugify(pageContext.slug.toLowerCase())}/`, name: `${pageContext.pageTitle}` }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <title>Receitas DIA - Receitas saborosas, rápidas e fáceis para você! - {pageContext.pageTitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={`No DIA Supermercados, além dessa receita, você também encontra receitas de Sobremesas, Pães e Salgados, dentre outras. Confira! - ${pageContext.pageTitle}`} />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section id="receitas">
        <section id="filtragem-home">
          <div className="menu-receitas">
            <Menu handleActive={categoriaSlug} />
          </div>
        </section>
        <section className='container'>
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className='d-block d-sm-none' >
                <InputSearch />
              </div>
              <div className="d-flex row">
                <div className="col-12">
                  <h2 className="text-red font-size-40px mb-3">TAGS</h2>
                  <h2 className="mb-3 text-uppercase font-size-17 mr-2">Confira todas as receitas relacionadas a tag&nbsp;&nbsp;
                    <b className='text-blue bg-dia-gray text-dark rounded py-2 px-1'>{pageContext.pageTitle} <span className='font-size-12'>({pageContext.totalLenght})</span></b>
                  </h2>
                </div>
                {receitas.map((rec, index) => (
                  <div className={`col-6 mb-4 ${index % 2 === 0 ? 'padding-right-custom-cat' : 'padding-left-custom-cat'}`} key={index}>
                    <Link to={`/receitas/${rec.node.categoriaSlug}/${rec.node.slug}/`} className="text-dark">
                      <div className="card cardReceita">
                        <div className='d-flex flex-column align-items-start justify-content-end w-100'>
                          <GatsbyImage
                            image={rec.node.image.localFile.childImageSharp.gatsbyImageData}
                            className='imgReceita'
                            alt="Imagem da Receita" />
                          <p className='position-absolute font-Yanone font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3 text-center'>{rec.node.categoria}</p>
                        </div>
                        <div className="card-body py-2 px-4">
                          <p className='font-size-15 text-left mb-0 limit-text-lg font-Yanone'><span>{rec.node.title}</span></p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-center align-items-center py-3'>
                <button
                  className={`button-pagination ${isFirst ? 'disabled-link bg-transparent border-0 px-4 mr-1' : 'bg-transparent border-0 px-4 mr-1'}`}
                  disabled={isFirst}
                  rel="prev"
                  onClick={() => navigate(`/receitas/tags/${pageContext.slug}/${isFirst ? '' : `${prevPage}`}`)}
                >
                  <i className={!isFirst ? 'arrow-black arrow-left' : 'arrow-gray arrow-left'}></i>
                </button>
                <div className="row justify-content-center">
                  {Array.from({ length: numPages }, (_, i) => {
                    return (
                      <div key={i}>
                        {(i === currentPage - 1) || (i === currentPage) || (isFirst ? i === currentPage + 1 : i === currentPage - 2)
                          ? <div className="col d-flex justify-content-center px-1">
                            <span>
                              <button
                                key={`pagination-number${i + 1}`}
                                onClick={() => navigate(`/receitas/tags/${pageContext.slug}/${i === 0 ? '' : `${i + 1}/`}`)}
                                className={`button-pagination ${i + 1 === currentPage ? 'rounded px-3 py-2 text-white btn-water-green border-0' : 'px-3 py-2 text-dark border-0 bg-transparent'}`}
                                style={{ outline: 'none' }}
                              >
                                {i + 1}
                              </button>
                            </span>
                          </div>
                          : ''}
                      </div>
                    )
                  })}
                </div>
                <button
                  onClick={() => navigate(`/receitas/tags/${pageContext.slug}/${isLast ? `${currentPage === 1 ? '' : currentPage}` : `${nextPage}/`}`)}
                  rel="next"
                  disabled={isLast}
                  className={`button-pagination ${isLast ? 'disabled-link bg-transparent border-0 px-4' : 'bg-transparent border-0 px-4'}`}
                >
                  <i className={!isLast ? 'arrow-black arrow-right' : 'arrow-gray arrow-right'}></i>
                </button>
              </div>
            </div>
            <div className='col-12 col-lg-4'>
              <SideBar />
            </div>
          </div>
        </section>
        <section className='container-fluid bg-dia-light-gray'>
          <section className="container py-5 p-0">
            <SectionLancamentos />
          </section>
        </section>
      </section>
    </Layout >
  );
}

export default ReceitasTags

export const query = graphql`query ($skip: Int!, $limit: Int!, $slugGraphql: String!) {
  receitas: allContentfulReceitas(
    sort: {fields: [createdAt], order: DESC}
    limit: $limit
    skip: $skip
    filter: {tagsReceitas: {glob: $slugGraphql}}
  ) {
    edges {
      node {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        id
        urLyoutube
        slug
        categoria
        categoriaSlug
        title
      }
    }
  }
  site: site {
    siteMetadata {
      tagManagerId
    }
  }
}
`
